.bgImgContainer {
    position: relative;
    height: 598px;
}

.bgImg { 
    height: 100%;
    width: 100%;
    object-fit: cover;
    background-origin: padding-box;
}

.description {
    text-align: center;
    padding-top: 46px;
}

.headerContainer {
    margin-top: 97px;
}

.headerIcon {
    height: 70px;
}

.headerTitle {
    margin-left: -40px !important;
    font-size: 700;
}

/* Hiring Process */

.hiringProcessContainer {
    margin-top: 87px !important;
    margin-bottom: 70px !important;
}