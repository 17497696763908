.container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 650px;
    background-color: var(--white-color);
    box-shadow: 24;
    padding: 15px 30px;
    border-radius: 5px;
}

.titleContainer {
    display: flex;
    justify-content: center;
}


.headerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerIcon {
    height: 60px;
}

.headerTitle {
    margin-left: -40px !important;
    font-size: 700;
}

.candidateName {
    font-weight: 400;
}

.interviewerEmail {
    margin-top: 20px !important;
}

.applicantEmail {
    margin-top: 20px !important;
}

.addDescription {
    margin-top: 20px !important;
}

.fieldLabel {
    color: #717171;
    margin-bottom: 4px !important;
}

.fullWidthField {
    width: 100%;
}

.textField {
    width: 100%;
    box-sizing: border-box;
    border: 0.8px solid #CCCCCC;
    border-radius: 5px;
    height: 45px;
    font-size: var(--small-size);
    padding: 0 16px;
}

.textAreaField {
    resize: vertical;
    padding: 5px 16px 0 16px;
}

/* date select */

.dateContainer {
    display: flex;
    column-gap: 24px;
    row-gap: 24px;
    margin-top: 20px !important;
}

.interviewDateContainer {
    width: calc(100% - 324px);
}

.dateField {
    width: 100%;
    box-sizing: border-box;
    border: 0.8px solid #CCCCCC;
    border-radius: 5px;
    height: 45px;
    font-size: var(--small-size);
    padding: 0 16px;
}

.interviewTimeContainer {
    display: flex;
}

.interviewTimeContainer input {
    border: none;
    padding: 0 16px;
    color: var(--text-color);
    font-size: var(--body1-size);
    height: 45px;
    width: 162px;
    border: 0.8px solid #CCCCCC;
    outline: none;
}

.fromTime input {
    border-right: 0.8px solid #CCCCCC;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.toTime input {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.controlBtnsContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px !important;
}

.controlBtn {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    font-size: var(--body1-size);
    font-weight: 400;
    border: 0.8px solid var(--primary-color);
    border-radius: 5px;
    cursor: pointer;
}

.cancelBtn {
    background-color: var(--white-color);
    color: var(--primary-color);
}

.sendBtn {
    background-color: var(--primary-color);
    color: var(--white-color);
    margin-left: 20px !important;
}

.controlBtn img {
    margin-left: 12px !important;
}