/* Join Us */
.container {
    min-height: 712px;
    background-color: var(--secondary-color);
    align-items: center;
    padding: 0 20px;
}


.leftContainer {
    padding: 20px 0;
}

.headerContainer {
    margin-bottom: 72px !important;
}

.headerIcon {
    height: 70px;
}

.headerTitle {
    margin-left: -40px !important;
    font-size: 700;
}

.title {
    text-align: center !important;
    margin-bottom: 24px !important;
}

.description {
    text-align: center !important;
    margin-bottom: 72px !important;
    padding-right: 50px !important;
    color: var(--text-color);
}

.searchBtn {
    width: 186px;
    height: 60px;
    background-color: var(--primary-color);
    border-radius: 5px;
    color: var(--white-color);
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.imgContainer {
    justify-content: center;
    padding: 20px 0;
}

.img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
    vertical-align: bottom;
}

/* When the browser is at least 1536px and above // xs */
@media (min-width: 0px) {

}
/* When the browser is at least 600px and above // sm */
@media (min-width: 600px) {
}

/* When the browser is at least 900px and above // md */
@media (min-width: 900px) {

}

/* When the browser is at least 1200px and above // lg */
@media (min-width: 1200px) {

}

/* When the browser is at least 1535px and below // xl */
@media (max-width: 1535px) {
    .headerContainer {
        margin-bottom: 30px !important;
    }

    .description {
        margin-bottom: 30px !important;
        padding-right: 0px !important;
        text-align: center !important;
    }
}

/* When the browser is at least 1536px and above // xl */
@media (min-width: 1536px) {
    .container {
        padding: 0 112px !important;
    }

    .leftContainer {
        padding: 0 !important;
    }

    .imgContainer {
        justify-content: flex-end;
        padding: 0 !important;
    }
}