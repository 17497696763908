.contactForm {
    padding: 50px 70px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 96px !important;
    margin-bottom: 164px !important;
}

.headerContainer {
}

.headerIcon {
    height: 70px;
}

.headerTitle {
    margin-left: -40px !important;
    font-size: 700;
}

.fieldContainer {
    margin-top: 28px !important;
}

.fieldNameContainer {
    margin-bottom: 8px;
}

.fieldName {
    color: #717171;
}

.textAreaContainer {
    margin-top: 31px !important;
}

.textArea {
    resize: vertical;
    width: 99.2%;
    font-size: var(--body1-size);
    height: 254px !important;
    outline-color: #3f50b5;
    font-family: Roboto !important;
    border-color: rgba(0, 0, 0, 0.23) !important;
    border-radius: 4px !important;
    padding: 10px;
}

.btn {
    color: var(--white-color);
    font-size: 20px;
    font-weight: 500;
    margin-top: 40px !important;
    border-radius: 5px;
    background-color: var(--primary-color);
    border: none;
    padding: 19px 24px;
    cursor: pointer;
}

/* When the browser is at least 600px and below // sm */
@media (max-width: 600px) {
    .contactForm {
        margin-left: auto;
        margin-right: auto;
        padding: 70px 30px !important;
    }
}
/* When the browser is at least 600px and below // sm */
@media (max-width: 1200px) {
    .contactForm {
        padding: 80px 70px;
    }
}