.cardContainer {
    width: 450px !important;
    background: var(--white-color);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    padding: 40px;
    margin: calc(30px / 2);
}

.priceText {
    margin-top: 32px !important;
    color: var(--text-color);
}

.price {
    font-weight: 700;
}

.listContainer {
    margin-top: 25px !important;
    list-style-position: inside;
    padding: 0;
}

.list {
    color: var(--text-color);
    font-size: var(--h3-size);
    margin-top: 16px !important;
}

.list span {
    position: relative;
    left: -10px;
  }