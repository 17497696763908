.container {
    background-color: #F5F5F5;
    padding: 0 112px;
}

.topContainer {
    display: flex;
    padding-top: 20px;
}

.bottomContainer {
    margin-top: 10px;
    padding-bottom: 10px;
}


img {
    cursor: pointer;
}

.leftSideContainer {
    width: 500px;
    font-size: var(--smallest-size);
}


.socialMediaContainer {
    margin-top: 15px;
    display: flex;
}

.iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 25px;
    margin: 0 7px;
    border-radius: 50px;
    cursor: pointer;
    background-color: var(--primary-color);
}

.rightSideContainer {
    display: flex;
    padding-left: 100px;
}

.rightSideContainer div:not(:last-child) {
    padding-right: 150px;
}

.rightSideContainer p {
    cursor: pointer;
}

.rightSideContainer a {
    text-decoration: none;
    color: black;
}


/* When the browser is at least 1536px and above // xs */
@media (min-width: 0px) {

}
/* When the browser is at least 600px and above // sm */
@media (min-width: 600px) {
}

/* When the browser is at least 600px and above // sm */
@media (max-width: 850px) {
    .container {
        padding: 20px 25px;
    }

    .topContainer {
        flex-wrap: wrap;
    }

    .linksContainer {
        display: grid;
        column-count: 2;
    margin: 11px 0 18px 0;
    }

    .leftSideContainer {
        width: fit-content;
    }

    .rightSideContainer {
        padding-left: 0;
    }

    .rightSideContainer {
        display: block;
        padding-left: 0;
    }
}

/* When the browser is at least 900px and below // md */
@media (max-width: 1000px) {
    .rightSideContainer div:not(:last-child) {
        padding-right: 70px;
    }
}

/* When the browser is at least 1200px and above // lg */
@media (min-width: 1200px) {

}

/* When the browser is at least 1536px and above // xl */
@media (min-width: 1536px) {

}