/* Special In Price Container */

.container {
    padding: 0 20px;
}

.leftSideContainer {
    justify-content: center !important;
    flex-direction: column !important;
}

.headerContainer {
    margin-bottom: 72px !important;
}

.headerIcon {
    height: 70px;
}

.headerTitle {
    margin-left: -40px !important;
}

.list {
    font-size: 24px;
    font-weight: 700;
    color: var(--primary-color);
    margin-bottom: 32px;
}

.listSpan {
    color: var(--text-color);
    font-weight: 400;
}

.imgContainer {
    justify-content: center !important;
    padding-top: 20px !important;
}

.img {
    max-width: 100% !important;
    height: auto !important;
    object-fit: cover !important;
    vertical-align: bottom !important;
}


/* When the browser is at least 1536px and above // xs */
@media (min-width: 0px) {

}
/* When the browser is at least 600px and above // sm */
@media (min-width: 600px) {
}

/* When the browser is at least 900px and below // md */
@media (max-width: 900px) {
    .headerIcon {
        display: none;
    }
    .headerTitle {
        margin-top: 50px !important;
        text-align: center;
        margin-left: 0 !important;
        width: 100%;
    }
}

/* When the browser is at least 1200px and above // lg */
@media (min-width: 1200px) {
  .container {
      padding: 0 112px;
  }

  .leftSideContainer {
      padding: 20px 0 !important;
  }
}

/* When the browser is at least 1536px and above // xl */
@media (min-width: 1536px) {
  .leftSideContainer {
      padding: 0 !important;
  }

  .imgContainer {
      padding-top: 0 !important;
  }
}