.candidateTest {
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    margin: 20px 0;
}

.infoData {
    font-weight: 400;
    font-size: var(--smallest-size);
    margin: 3px 0;
}

.infoLabel {
    color: #717171;
    font-size: var(--smallest-size);
    font-weight: bold;
}

.note{
    margin: 2px 0;
    margin-left: 10px;
}

.nullValue {
    color: #d3d3d3
}