body {
  margin: 0;
  font-family: Roboto;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*, html, body {
  scroll-behavior: smooth !important;
}

:root {
  --primary-color: #42BF76;
  --secondary-color: #F5F5F5;
  --heading-color: #000000;
  --text-color: #4B4B4B; 
  --white-color: #FFFFFF;
  --h1-size: 48px;
  --h2-size: 32px;
  --h3-size: 24px;
  --body1-size: 20px;
  --small-size: 18px;
  --smallest-size: 16px;
  --rm-audio-player-interface-container: white !important;
}

@font-face {
  font-family: Roboto;
  src: url("../Fonts/Roboto/Roboto-Thin.ttf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: Roboto;
  src: url("../Fonts/Roboto/Roboto-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: Roboto;
  src: url("../Fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: Roboto;
  src: url("../Fonts/Roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: Roboto;
  src: url("../Fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: Roboto;
  src: url("../Fonts/Roboto/Roboto-Black.ttf") format("truetype");
  font-weight: 900;
}

.ql-container {
  min-height: 10em;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc;
}

.ql-toolbar {
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.rm-audio-player-provider {
  border-radius: 20px !important;
}

.rm-audio-player-provider .interface-grid {
  border-radius: 20px !important;
}

.calendly-badge-widget {
  position: static !important;
}