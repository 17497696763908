.jobContainer {
    padding: 32px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.inactiveJob {
    opacity: 0.5;
}

.jobTitle {

}

.jobIconContainer {
    width: fit-content !important;
}

.jobIconContainer img {
    cursor: pointer;
}

.jobBodyContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-end;
}

.jobDetailsContainer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 12px;
    margin-top: 16px;
}

.viewJobBtn {
    display: flex;
    justify-content: center;
    height: fit-content;
    width: fit-content;
    font-size: var(--body1-size);
    align-items: center;
    background: var(--primary-color);
    border: 0.8px solid var(--primary-color);
    color: var(--white-color);
    border-radius: 5px;
    padding: 16px 24px;
    cursor: pointer;
}

.viewJobBtn img {
    margin-left: 8px !important;
}

.locationVacanies {
    color: #717171;
}

.postDate {
    color: #1F8EBA;
}

.jobTypesContainer {
    display: flex;
    width: 100%;
}

.jobTypeContainer {
    display: flex;
    align-items: center;
    column-gap: 8px;
}

.jobTypeIconText {
    color: var(--text-color);
}

.workFromHomeContainer {
    margin-left: 43px !important;
}

.navigateBtn {
    text-decoration: none;
}


/* When the browser is at least 1536px and beyond // xs */
@media (max-width: 0px) {

}
/* When the browser is at least 600px and beyond // sm */
@media (max-width: 600px) {
    .jobContainer {
        padding: 30px 15px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
    }

    .jobBodyContainer {
        flex-direction: column !important;
        align-items: center;
        justify-content: flex-end;
    }

    .jobDetailsContainer {
        display: flex;
        flex-direction: column !important;
        align-items: center;
        row-gap: 12px;
        margin-top: 16px;
    }

    .jobTypesContainer {
        flex-direction: column !important;
        align-items: center;
        row-gap: 12px;
    }

    .workFromHomeContainer {
        margin-left: 0 !important;
    }

    .viewJobBtn {
        margin-top: 20px;
    }
}

/* When the browser is at least 900px and beyond // md */
@media (max-width: 900px) {

}

/* When the browser is at least 1200px and beyond // lg */
@media (max-width: 1200px) {

}

/* When the browser is at least 1536px and beyond // xl */
@media (max-width: 1536px) {

}