.headerContainer {
    margin-top: 70px;
}

.headerIcon {
    height: 70px;
}

.headerTitle {
    margin-left: -40px !important;
    font-size: 700;
}

.pricingContainer {
    margin-top: 30px !important;
    margin-bottom: 148px !important;
}