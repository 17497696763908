.container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 650px;
    background-color: var(--white-color);
    box-shadow: 24;
    padding: 15px 30px;
    border-radius: 5px;
}

.titleContainer {
    display: flex;
    justify-content: center;
}


.headerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerIcon {
    height: 60px;
}

.headerTitle {
    margin-left: -40px !important;
    font-size: 700;
}

.candidateName {
    font-weight: 400;
}

.sendJobToInvTo {
    margin-top: 20px !important;
}

.emailPreviewContainer {
    margin-top: 20px !important;
}

.fieldLabel {
    color: #717171;
    margin-bottom: 4px !important;
}

.fullWidthField {
    width: 100%;
}

select {
    appearance: none;
    background-image: url("../../../assets/images/down-arrow-icon.svg");
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: calc(100% - 16px) center;
}

.selectField {
    width: 100%;
    box-sizing: border-box;
    border: 0.8px solid #CCCCCC;
    border-radius: 5px;
    height: 45px;
    font-size: var(--small-size);
    padding-right: 50px;
    padding-left: 16px; 
}

.emailPreview {
    box-sizing: border-box;
    border: 0.8px solid #CCCCCC;
border-radius: 5px;
    width: 100%;
    padding: 16px;
}

.emailPreviewText {
    font-size: var(--body1-size);
    color: #4B4B4B;
}

.boldText {
    color: var(--heading-color);
    font-weight: 700;
}

.controlBtnsContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px !important;
}

.controlBtn {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    font-size: var(--body1-size);
    font-weight: 400;
    border: 0.8px solid var(--primary-color);
    border-radius: 5px;
    cursor: pointer;
}

.cancelBtn {
    background-color: var(--white-color);
    color: var(--primary-color);
}

.sendBtn {
    background-color: var(--primary-color);
    color: var(--white-color);
    margin-left: 20px !important;
}

.controlBtn img {
    margin-left: 12px !important;
}