.container {
    margin-top: 20px;
    margin-bottom: -50px;
}

.fieldErrorContainer {
    margin: 15px 0;
}

.errorMsg {
    margin: 5px 0;
    display: block;
    color: red;
    font-weight: bold;
}