.container {
    display: flex;
    flex-wrap: wrap;
    row-gap: 32px;
    width: 100%;
    margin-top: 72px !important;
}

.questionAnswerContainer {
    width: 100%;
}

.questionContainer {
    display: flex;
    align-items: center;
}

.questionContainer img {
    margin-left: 10px;
}

.greenRec {
    width: 8px;
    height: 29px;
    background-color: var(--primary-color);
    margin-right: 11px !important;
}

.question {
    font-size: var(--h3-size);
    font-weight: 400;
}

.answerContainer {
    margin-top: 15px !important;
}

.textAnswer {
    color: #717171;
    font-size: var(--body1-size);
}