.container {
    display: flex;
}

.imageContainer {
    display: none;
    position: sticky;
    top: 0;
    height: 100vh;
    width: 50%;
    background: url("../../assets/images/auth-bg-image.png");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; 
}

.rightSideContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
}

.formContainer {
    padding: 30px;
    width: 85%;
}


/* title */

.titleContainer {
    display: flex;
    justify-content: center;
}


.headerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerIcon {
    height: 60px;
}

.headerTitle {
    margin-left: -40px !important;
    font-size: 700;
}


.halfWidthField {
    width: 50%;
}

.fullWidthField {
    width: 100%;
}

.fieldLabel {
    color: #717171;

}

.textField {
    width: 100%;
    box-sizing: border-box;
    border: 0.8px solid #CCCCCC;
    border-radius: 5px;
    height: 40px;
    font-size: var(--small-size);
    margin-top: 8px !important;
    padding-left: 10px;
}

.firstname {
    margin-top: 21px !important;
}

.lastname {
    margin-top: 11px !important;
}

.email {
    margin-top: 11px !important;
}

.password {
    margin-top: 11px !important;
}

.confirmPassword { 
    margin-top: 11px !important;
}

.signUpBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--primary-color);
    color: var(--white-color);
    border: 0.8 solid var(--primary-color);
    border-radius: 5px;
    padding-top: 18px;
    padding-bottom: 18px;
    width: 100%;
    font-size: var(--body1-size);
    margin-top: 24px !important;
    cursor: pointer;
    margin-top: 24px !important;
}

.signUpBtn img {
    margin-left: 8px !important;
}

.existingUser {
    margin-top: 16px !important;
    text-align: center;
    font-size: var(--small-size) !important;
    color: var(--text-color);
}

.existingUser span {
    color: var(--primary-color);
    cursor: pointer;
}

.fieldErrorContainer {
    margin: 15px 0;
}

.errorMsg {
    margin: 5px 0;
    display: block;
    color: red;
    font-weight: bold;
}

/* When the browser is at least 1536px and above // xs */
@media (min-width: 0px) {

}
/* When the browser is at least 600px and above // sm */
@media (min-width: 600px) {
}

/* When the browser is at least 900px and above // md */
@media (min-width: 900px) {
    .imageContainer {
        display: block;
    }

    .rightSideContainer {
        width: 50%;
    }

    .formContainer {
        width: 70%;
    }

    .textField {
        height: 56px;
    }
}

/* When the browser is at least 1200px and above // lg */
@media (min-width: 1200px) {

}

/* When the browser is at least 1536px and above // xl */
@media (min-width: 1536px) {

}