.container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 20px;
}

.drawer {
    border-radius: 50px !important;
}

.drawerContentContainer {
    position: relative;
    background-color: white;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.listContainer {
    margin-top: 5px;
    width: 200px;
}

.burgerIcon {
    font-size: 28px;
}

.logoContainer {
    display: flex;
    align-items: center;
    box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.1);
}

.logoImg {
    height: 38px;
    width: 180px;
    margin: 13px auto 10px auto;
}

.listContainer > .navLink > li {
    border-radius: 8px;
    margin: 10px auto;
    width: 92%;
}

.navLink {
    text-align: center;
    color: var(--black-color);
    text-decoration: none;
    border-bottom: 0.8px solid rgba(0, 0, 0, 0.25);
    width: 100%;
}

.listContainer > .navLink > li:has(> .activeLink) {
    background-color: var(--primary-color);
    color: var(--white-color);
    border-bottom: none;
}

.linkText {
    margin-left: 7px;
}

.notLoggedInContainer {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;

}

.notLoggedInText {
    display: flex;
    justify-content: center;
}

.notLoggedInItem {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 0 10px;
}

.divider {
    margin-bottom: 10px;
}

.notLoggedInIcon {
    margin-right: 10px;
}

.notLoggedInContainer p {
    margin: 0;
    font-size: var(--body1-size);
}