.container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 20px;
    right: 25px;
    z-index: 10000000;
    background-color: var(--primary-color);
    height: 50px;
    width: 50px;
    border-radius: 50px;
    cursor: pointer;
}

.topCalendlyCookie {
    bottom: 120px;
}

.topCookie {
    bottom: 70px;
}

.topCalendly {
    bottom: 65px;
}

.arrowIcon {
    font-size: 37px;
    color: var(--white-color);
}