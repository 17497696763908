.questionsContainer {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 37px;
}

.fullWidthField {
    width: 100%;
}

.fieldLabel {
    color: #717171;
    margin-bottom: 8px !important;
}

.fieldLabel img {
    margin-right: 5px;
}

.recordHelperText {
    font-size: var(--smallest-size);
    font-weight: 400;
    display: block;
    letter-spacing: -0.3px;
    line-height: 20px;
    color: rgb(128, 142, 165);
}