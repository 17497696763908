.hiringProcessContainer {
    width: fit-content;
    margin: 30px calc(53px / 2) !important;
}

.circleContainer {
    position: relative;
}

.numbering {
    position: absolute;
    left: 50px;
}

.bgCircle {
    height: 180px;
    width: 180px !important;
    border-radius: 50%;
    background-color: var(--secondary-color);
}

.icon {
    height: 80px;
    width: 80px;
}


.verticalLine {
    height: 61px;
    margin-top: 8px !important;
    width: 4px;
    background-color: #CCCCCC;
}

.title {
    margin-top: 8px !important;
    text-align: center;
}

.description {
    margin-top: 8px !important;
    width: 384px;
    color: var(--text-color);
    text-align: center;
    line-height: 30px;
}