.mainContainer {
    display: flex;
    justify-content: center;
}

.container {
    width: 1296px;
    margin-bottom: 96px !important;
}

.stagesContainer {
    display: flex;
    justify-content: center;
    margin-top: 92px !important;
    position: relative;
}

.stageContainer{
    background: var(--secondary-color);
    color: #717171;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 57px;
    clip-path: polygon(85% 0%, 100% 50%, 85% 100%, 0% 100%, 15% 50%, 0% 0%);
    cursor: pointer;
}

.activeStageContainer {
    background-color: var(--heading-color);
    color: var(--white-color);
    font-weight: 700;
}

.textTitle {
    font-weight: 400;
}


.profileContainer {
    margin-top: 56px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 38px 32px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 20px !important;
}

.leftSideProfile {
    display: flex;
}

.profileImg {
    margin-right: 13px;
}

.jobTitle {
    font-weight: 400;
    color: var(--text-color);
    margin: 8px 0 !important;
}

.applyDate {
    font-weight: 400;
    color: #1F8EBA;
}

.controlBtnsContainer {
    display: flex;
    align-items: center;
    column-gap: 16px;
}

.controlBtnContainer {
    display: flex;
    align-items: center;
    padding: 18px 24px;
    border-radius: 5px;
    border: 0.8px solid var(--primary-color);
    font-size: var(--body1-size);
    font-weight: 500;
    cursor: pointer;
}

.scheduleOnlineBtn {
    background-color: var(--primary-color);
    color: var(--white-color);
}

.inviteToAnotherJobBtn {
    background-color: var(--white-color);
    color: var(--primary-color);
}

.controlIcon {
    margin-left: 9px !important;
}

.candidateInfoContainer {
    margin-top: 56px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 64px 112px !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


/* Tab section */

.tabsContainer {
    display: flex;
    justify-content: center;
    column-gap: 24px;
    width: 100%;
}

.tabContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content !important;
    cursor: pointer;
}

.tabIcon {
    height: 56px;
}

.tabTitle {
    margin-left: -30px !important;
    font-weight: 400;
    color: #717171;
}

.activeTabTitle {
    color: var(--black-color);
    font-size: 700;
}

/* When the browser is at least 1536px and above // xs */
@media (min-width: 0px) {
    .textTitle {
        font-size: 10px !important;
    }
}
/* When the browser is at least 600px and above // sm */
@media (min-width: 600px) {
    .textTitle{ 
        font-size: var(--small-size) !important;
    }
}

/* When the browser is at least 900px and above // md */
@media (min-width: 900px) {
    .textTitle{ 
        font-size: var(--h3-size) !important;
    }
}

/* When the browser is at least 1200px and above // lg */
@media (min-width: 1200px) {
}

/* When the browser is at least 1536px and above // xl */
@media (min-width: 1536px) {

}

/*
vertical arrow
.stagesContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 92px !important;
}

.stageContainer{
    background: var(--secondary-color);
    color: #717171;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 100px;
    transform: rotate(90deg);
    clip-path: polygon(85% 0%, 100% 50%, 85% 100%, 0% 100%, 15% 50%, 0% 0%);
}

.textTitle {
    font-weight: 400;
    transform: rotate(270deg);
    font-size: var(--small-size);
}
*/