.applicationContainer {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 24px 16px;
    cursor: pointer;
}

.applicationInfoContainer {
    display: flex;
    column-gap: 16px;
}

.profilePic {
    height: 64px;
    border-radius: 50%;
}

.applicationDetailsContainer {
}

.name {

}

.location {
    margin-top: 8px !important;
    color: #717171;
}

.yearsExperience {
    margin-top: 4px !important;
    color: var(--text-color);
}

.applyDate {
    margin-top: 4px !important;
    color: #717171;
}

.matchContainer {
    margin-top: 16px !important;
    padding: 10px 16px;
    font-size: var(--small-size);
    border-radius: 5px;
    width: fit-content;
}

.lowMatchContainer {
    background: rgba(182, 0, 0, 0.08);
    color: #B60000;
}

.regularMatchContainer {
    background: rgba(216, 139, 23, 0.08);
    color: #D88B17;
}

.highMatchContainer {
    background: rgba(0, 182, 51, 0.08);
    color: #00B633;
}


.viewCandidateBtnContainer {
    margin-top: 20px;
    width: 100%;
}

.navigateBtn {
    text-decoration: none;
}

.viewCandidateBtn {
    display: flex;
    justify-content: center;
    height: fit-content;
    width: 100%;
    font-size: var(--body1-size);
    align-items: center;
    background: var(--primary-color);
    border: 0.8px solid var(--primary-color);
    color: var(--white-color);
    border-radius: 5px;
    padding: 12px 20px;
    cursor: pointer;
}