.container {
    display: flex;
    justify-content: center;
}

.mainContainer {
    width: 1072px;
    padding: 0 30px;
    margin-bottom: 50px;
}

.controlBtnsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: 20px !important;
    row-gap: 15px;
}

.closeBtn {
    display: flex;
    justify-content: center;
    height: fit-content;
    width: fit-content;
    font-size: var(--body1-size);
    align-items: center;
    background: var(--white-color);
    border: 0.8px solid var(--primary-color);
    color: var(--primary-color);
    border-radius: 5px;
    padding: 16px 24px;
    cursor: pointer;
}

.closeBtn img {
    margin-left: 8px !important;
}

.applyForJobBtn {
    display: flex;
    justify-content: center;
    height: fit-content;
    width: fit-content;
    font-size: var(--body1-size);
    align-items: center;
    background: var(--primary-color);
    border: 0.8px solid var(--primary-color);
    color: var(--white-color);
    border-radius: 5px;
    padding: 16px 24px;
    cursor: pointer;
    margin-left: 24px !important;
}

.applyForJobBtn img {
    margin-left: 8px !important;
}

.jobDetails {
    margin-top: 24px !important;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 32px 24px;
}

.jobDetailsData {
    display: grid;
    margin-top: 24px !important;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 16px;
}

.jobDetailData {
    color: #717171;
}

.jobDetailValue {
    color: #000000;
}

.applicationStatus {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 20px 24px !important;
    margin-top: 40px !important;
    margin-bottom: 32px !important;
    word-break: break-all;
    text-align: center;
}

.applicationStatus p {
    margin-bottom: 0;
    color: var(--text-color);
}

.dividerStyle {
    margin: 20px auto;
}

.alertInfo {
    margin-top: 20px;
}

.jobDescription {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 32px 24px !important;
    margin-top: 40px !important;
    margin-bottom: 32px !important;
    word-break: break-all;
}

.jobDescriptionData {
    margin: 24px 0 !important;
}

.jobRequirementsData {
    margin-top: 24px !important;
}

.screeningQuestions {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 32px 24px !important;
}

.screeningQuestionsContainer {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    row-gap: 8px !important;
    margin-top: 24px !important;
}

.screeningQuestion {
    display: flex;
    width: 100%;
    color: var(--text-color);
}

.screeningQuestion img {
    margin-right: 8px !important;
}

.disabledBtn {
    opacity: 0.5;
    cursor: default;
}


/* When the browser is at least 1536px and beyond // xs */
@media (max-width: 0px) {

}
/* When the browser is at least 600px and beyond // sm */
@media (max-width: 600px) {
    .controlBtnsContainer button {
        width: 100%;
        margin-left: 0px !important;
    }

    .jobDetailsData {
        grid-template-columns: repeat(1, 1fr);
    }

    .jobDetailsData h3 {
        font-size: var(--body1-size);
    }
}

/* When the browser is at least 900px and beyond // md */
@media (max-width: 900px) {

}

/* When the browser is at least 1200px and beyond // lg */
@media (max-width: 1200px) {

}

/* When the browser is at least 1536px and beyond // xl */
@media (max-width: 1536px) {

}