.container {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

.profileContainer {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    padding: 25px 50px;
    border-radius: 5px;
}

.updateProfileContainer { 
    margin-top: 50px;
}

.controlBtnContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.controlBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: fit-content;
    padding: 12px 20px;
    border: none;
    font-size: var(--h3-size);
    border-radius: 5px;
    margin-bottom: 24px;
}

.cancelBtn {
    background-color: var(--white-color);
    border: 0.8px solid var(--primary-color);
    color: var(--primary-color);
    cursor: pointer;
}

.updateBtn {
    margin-left: 20px;
    background-color: #E8E8E8;
    border: 0.8px solid #E8E8E8;
    color: #717171;
}

.activeUpdateBtn {
  background-color: var(--primary-color);
  color: var(--white-color);
  cursor: pointer;
}

.controlIcon {
    height: 20px !important;
    width: 20px !important;
    margin-left: 10px;
}

/* title */

.titleContainer {
    display: flex;
    justify-content: center;
}

.headerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerIcon {
    height: 60px;
}

.headerTitle {
    margin-left: -40px !important;
    font-size: 700;
}

.formContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px !important;
    row-gap: 30px !important;
}


.halfWidthField {
    width: 100%;
}

.fullWidthField {
    width: 100%;
}

.leftField {
    box-sizing: border-box !important;
}

.fieldLabel {
    color: #717171;
    margin-bottom: 4px !important;
}

.textField {
    width: 100%;
    box-sizing: border-box;
    border: 0.8px solid #CCCCCC;
    border-radius: 5px;
    height: 56px;
    padding-left: 16px;
    font-size: var(--small-size);
}

select {
    appearance: none;
    background-image: url("../../assets/images/down-arrow-icon.svg");
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: calc(100% - 16px) center;
}

.selectField {
    width: 100%;
    box-sizing: border-box;
    border: 0.8px solid #CCCCCC;
    border-radius: 5px;
    height: 56px;
    font-size: var(--small-size);
    padding-right: 50px;
    padding-left: 16px; 
}


.changePasswordBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--white-color);
    color: var(--primary-color);
    border: 0.8px solid var(--primary-color) !important;
    border-radius: 5px;
    padding: 18px 24px;
    font-size: var(--body1-size);
    cursor: pointer;
}

.changePasswordBtn img {
    margin-left: 8px !important;
}


/* When the browser is at least 1536px and above // xs */
@media (min-width: 0px) {
}
/* When the browser is at least 600px and above // sm */
@media (max-width: 600px) {
    .profileContainer {
        margin-left: 20px;
        margin-right: 20px;
        padding: 25px 30px;
    }
    
    .changePasswordBtn {
        width: 100%;
    }
}

/* When the browser is at least 900px and above // md */
@media (min-width: 900px) {
    .container {
        margin-top: 0 !important;
    }
    .halfWidthField {
        width: 50%;
    }

    .leftField {
        padding-right: 56px !important;
    }
}

/* When the browser is at least 1200px and below // lg */
@media (max-width: 1200px) {
    .profileContainer {
        margin-left: 30px;
        margin-right: 30px;
    }
}

/* When the browser is at least 1536px and above // xl */
@media (min-width: 1536px) {

}