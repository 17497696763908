.navbarContainer {
    padding: 0 60px;
    height: 64px;
    z-index: 100 !important;
    position: fixed;
    top: 0;
    background-color: white;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.navbarContentContainer {
    display: flex;
    width: 100%;
}

.linksContainer {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.iconContainer {
    display: flex;
    align-items: center;
}

.logo {
    height: 38px;
    width: 198px;
    cursor: pointer;
}

.navbarLink {
    margin: 0 20px !important;
    cursor: pointer;
    margin-top: 8px !important;
    padding-bottom: 8px !important;
}

.activeLink {
    border-bottom: 5px solid var(--primary-color) !important;
}

.routerLink {
    text-decoration: none;
    color: var(--black-color);
}

@media (max-width: 0px) {

}
/* When the browser is at least 600px and below // sm */
@media (max-width: 600px) {
    .navbarContainer {
        padding: 0 15px;
    }
}

/* When the browser is at least 900px and above // md */
@media (min-width: 900px) {
}

/* When the browser is at least 1200px and below // lg */
@media (max-width: 1200px) {
    .iconContainer {
        width: 100%;
        justify-content: center;
    }
}

/* When the browser is at least 1536px and above // xl */
@media (min-width: 1536px) {

}