.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #DA504D;
    color: white;
    border-radius: 5px;
    margin-top: 20px;
    padding: 10px 30px;
}

.error {
    background-color: #DA504D;
}

.info {
    background-color: #0288d1;
}

.container p {
    margin: 0;
}

.verifyBtn {
    color: white;
    background-color: transparent;
    border: 1px solid white;
    padding: 7px 20px;
    border-radius: 5px;
    cursor: pointer;
}