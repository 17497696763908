.personalInfoContainer {
    margin-top: 48px !important;
    width: 100%;
    padding: 32px 24px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.personalInfoDataContainer {
    margin-top: 24px !important;
    display: grid;
    row-gap: 15px;
    grid-template-columns: repeat(2, 1fr);
}

.infoData {
    font-weight: 400;
    font-size: var(--h3-size);
}

.infoData a {
    color: #551A8B;
}

.infoLabel {
    color: #717171;
}

.reminder {
    font-weight: bold;
    font-size: var(--smallest-size);
}

.contactInfoContainer {
    margin-top: 40px !important;
    width: 100%;
    padding: 32px 24px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.contactInfoDataContainer {
    margin-top: 24px !important;
    display: grid;
    row-gap: 15px;
    grid-template-columns: repeat(2, 1fr);
}

.contactInfoContainer .link {
    grid-column: 1 / 3;
}

.testdomeBtn {
    border: none;
    border-radius: 5px;
    background-color: var(--primary-color);
    color: var(--white-color);
    padding: 15px 30px;
    font-size: var(--body1-size);
    width: 100%;
    margin-top: 20px !important;
    cursor: pointer;
}

.accordion {
    width: 100%;
    margin-top: 10px;

}

.accordionSummary {
    padding-left: 0 !important;
    width: 100%;
}

.notesContainer {
    margin-top: 48px !important;
    width: 100%;
    padding: 32px 24px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.notesDataContainer {
    display: flex;

}

.profileImg {
}

.disabled {
    opacity: 0.7;
}

.notesInput {
    box-sizing: border-box;
    height: 56px;
    width: 557px;
    border: 0.8px solid #CCCCCC;
    border-radius: 5px;
    margin-left: 8px;
    padding-left: 10px;
}

.helperTextContainer {
    display: flex;
    align-items: center;
    margin-left: 16px !important;
}


.helperText {
    margin-left: 4px !important;
    color: #717171;
    font-size: var(--small-size-size) !important;
}