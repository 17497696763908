.page {
    display: flex;
    justify-content: center;
}

.container {
    width: 1072px;
    padding: 0px 20px;
    margin-bottom: 50px;
}

.headerContainer {
    margin-top: 96px;
}

/* Tab section */

.tabsContainer {
    margin-top: 80px !important;
    column-gap: 24px;
    row-gap: 20px;
}

.tabContainer {
    width: fit-content !important;
}

.tabIcon {
    height: 56px;
}

.tabTitle {
    margin-left: -30px !important;
    font-weight: 400;
    color: #717171;
}

.activeTabTitle {
    color: var(--black-color);
    font-size: 700;
}

/* ContactUsList */

.contactUsListContainer {
    margin-top: 40px;
}

.emptyContactUsList {
    padding: 32px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.emptySontactUsList p {
    font-size: var(--h3-size);
}

.noContactUsListIcon {
    font-size: 150px !important;
    cursor: default;
}

.createdAt {
    font-size: var(--body1-size);
    color: #1F8EBA;
}

.contactUsListContainer {
    padding: 32px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 100%;
    margin: 0 auto;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.contactUsContainer {
    padding: 10px 32px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.contactUsContainer span {
    font-weight: bold;
}

/* When the browser is at least 1536px and beyond // xs */
@media (max-width: 0px) {

}
/* When the browser is at least 600px and beyond // sm */
@media (max-width: 600px) {
    .container {
        padding: 0px 20px;
    }

    .tabsContainer {
        margin-top: 30px !important;
        row-gap: 12px;
    }
}

/* When the browser is at least 900px and beyond // md */
@media (max-width: 900px) {

}

/* When the browser is at least 1200px and beyond // lg */
@media (max-width: 1200px) {

}

/* When the browser is at least 1536px and beyond // xl */
@media (max-width: 1536px) {

}