.cardContainer {
    margin: 0 16px;
    margin-top: 20px !important;
    height: 330px;
    width: 350px !important;
    border-radius: 5px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    padding: 0 15px !important;
}

.icon {
    height: 80px;
    width: 80px;
    margin-bottom: 16px !important;
}

.title {
    margin-bottom: 16px !important;
}