.container  {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    height: 500px;
    background-color: var(--white-color);
    border: 2px solid #000;

    padding: 30px;
    border: none;
    border-radius: 5px;
}

h2 {
    text-align: center;
}

.fullWidthField {
    width: 100%;
}

.formContainer {
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.fieldsContainer {

}

select {
    appearance: none;
    background-image: url("../../../../assets/images/down-arrow-icon.svg");
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: calc(100% - 16px) center;
}

.fieldLabel {
    color: #717171;
    margin-bottom: 4px !important;
}

.selectField {
    width: 100%;
    box-sizing: border-box;
    border: 0.8px solid #CCCCCC;
    border-radius: 5px;
    height: 56px;
    font-size: var(--small-size);
    padding-right: 50px;
    padding-left: 16px; 
}

.dateTimePicker {
    width: 100%;
}

.sendTest {
    border: none;
    border-radius: 5px;
    background-color: var(--primary-color);
    color: var(--white-color);
    padding: 15px 30px;
    font-size: var(--body1-size);
    width: 100%;
    margin-top: 20px !important;
    cursor: pointer;
}