.container {
    width: 1296px;
    margin-bottom: 96px !important;
}

.controlBtnContainer {
    margin-top: 96px;
}

.controlBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: fit-content;
    padding: 18px 24px;
    border: none;
    font-size: var(--h3-size);
    border-radius: 5px;
    margin-bottom: 24px;
}

.cancelBtn {
    background-color: var(--white-color);
    border: 0.8px solid var(--primary-color);
    color: var(--primary-color);
    cursor: pointer;
}

.submitBtn {
    background-color: #E8E8E8;
    border: 0.8px solid #E8E8E8;
    color: #717171;
}

.activeSubmitBtn {
  background-color: var(--primary-color);
  color: var(--white-color);
  cursor: pointer;
}

.controlIcon {
    height: 20px !important;
    width: 20px !important;
    margin-left: 10px;
}

.jobContainer {
    width: 100%;
    padding: 96px 112px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.headerContainer {
    margin-top: 61px !important;
}

.headerIcon {
    height: 70px;
}

.headerTitle {
    margin-left: -30px !important;
    font-size: 700;
}

.cardsContainer {
    margin-top: 72px !important;
}

/* Stepper */


.stepperWrapper {
    font-family: Arial;
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
  }
  .stepperItem {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
  
  .stepperItem::before {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: -50%;
    z-index: 2;
  }
  
  .stepperItem::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 2;
  }
  
  .stepperItem .stepCounter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--white-color);
    border: 1px solid #CCCCCC;
    margin-bottom: 6px;
    color: #CCCCCC;
  }
  
  .stepperItem.active {
    font-weight: bold;
  }

  .stepName {
    margin-top: 9px;
    color: #717171;
  }

  .stepperItem.completed .stepName {
    color: var(--black-color);
  }

  .stepperItem.active .stepName {
    color: var(--black-color);
  }

  .stepperItem.active .stepCounter {
    background-color: var(--primary-color);
    color: var(--white-color);
  }
  
  .stepperItem.completed .stepCounter {
    background-color: var(--primary-color);
    color: var(--white-color);
  }
  
  .stepperItem.completed::after {
    position: absolute;
    content: "";
    border-bottom: 0.8px solid #CCCCCC;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 3;
  }
  
  .stepperItem:first-child::before {
    content: none;
  }
  .stepperItem:last-child::after {
    content: none;
  }


.controlContainerBtn {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 96px;
}

.backBtn {
  cursor: pointer;
  background-color: var(--white-color);
  border: 0.8px solid var(--primary-color);
  padding: 22px 24px;
  border-radius: 5px;
}

.proceedBtn {
  cursor: pointer;
    background-color: var(--primary-color);
    border: none;
    padding: 22px 24px;
    border-radius: 5px;
}