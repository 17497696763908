.container {
    
}

.jobDetails {
    margin-top: 72px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 32px 24px;
}

.jobDetailsData {
    display: grid;
    margin-top: 24px !important;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 16px;
}

.jobDetailData {
    color: #717171;
}

.jobDetailValue {
    color: #000000;
}

.jobDescription {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 32px 24px !important;
    margin-top: 40px !important;
    margin-bottom: 32px !important;
}

.jobDescriptionData {
    margin: 24px 0 !important;
}

.dividerStyle {
    margin: 20px auto;
}

.jobRequirementsData {
    margin-top: 24px !important;
}

.screeningQuestions {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 32px 24px !important;
}

.screeningQuestionsContainer {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    row-gap: 8px !important;
    margin-top: 24px !important;
}

.screeningQuestion {
    display: flex;
    width: 100%;
    color: var(--text-color);
}

.screeningQuestion img {
    margin-right: 8px !important;
}