.container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.formContainer { 
    text-align: center;
    width: 85%;
}

/* title */

.titleContainer {
    display: flex;
    justify-content: center;
}

.headerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerIcon {
    height: 60px;
}

.headerTitle {
    margin-left: -40px !important;
    font-size: 700;
}

.helperText {
    color: var(--text-color);
    font-weight: 400;
    font-size:  var(--body1-size);
    margin-top: 16px !important;
}


.fullWidthField {
    width: 100%;
}

.fieldLabel {
    color: #717171;

}

.textField {
    width: 100%;
    box-sizing: border-box;
    border: 0.8px solid #CCCCCC;
    border-radius: 5px;
    height: 56px;
    font-size: var(--small-size);
    margin-top: 8px !important;
    padding-left: 10px;
}

.email {
    text-align: left;
    margin-top: 53px !important;
}

.forgotPasswordBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--primary-color);
    color: var(--white-color);
    border: 0.8 solid var(--primary-color);
    border-radius: 5px;
    padding-top: 18px;
    padding-bottom: 18px;
    width: 100%;
    font-size: var(--body1-size);
    margin-top: 24px !important;
    cursor: pointer;
    margin-top: 24px !important;
}

.forgotPasswordBtn img {
    margin-left: 8px !important;
}

.resendEmail {
    margin-top: 16px !important;
    text-align: center;
    font-size: var(--small-size) !important;
    color: var(--text-color);
}

.resendEmail span {
    color: var(--primary-color);
    cursor: pointer;
}



/* When the browser is at least 1536px and above // xs */
@media (min-width: 0px) {

}
/* When the browser is at least 600px and above // sm */
@media (min-width: 600px) {
}

/* When the browser is at least 900px and above // md */
@media (min-width: 900px) {
    .formContainer {
        width: 50%;
    }
}

/* When the browser is at least 1200px and above // lg */
@media (min-width: 1200px) {

}

/* When the browser is at least 1536px and above // xl */
@media (min-width: 1536px) {

}