.container {
    width: 1072px;
}

.headerContainer {
    margin-top: 96px;
}

.searchInputContainer {
    position: relative;
    display: flex;
    align-items: center;
    height: 45px;
    border: 0.8px solid #CCCCCC;
    border-radius: 5px;
    font-size: var(--small-size);
    padding-left: 16px;
    flex-grow: 1;
}

.searchInputContainer:focus-within{
}

.searchIcon {
    position: absolute;
    height: 16px;
    width: 16px;
}

.searchInput {
    margin-left: 25px;
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    background: none;
    font-size: var(--small-size);
}

.linkAddBtn {
    text-decoration: none;
}

.addBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px;
    background-color: var(--primary-color);
    color: var(--white-color);
    border: none;
    font-size: var(--small-size);
    border-radius: 5px;
    cursor: pointer;
}

.addIcon {
    height: 16px;
    width: 16px;
    margin-left: 8px;
}

/* Tab section */

.tabsContainer {
    margin-top: 50px !important;
    column-gap: 24px;
}

.tabContainer {
    cursor: pointer;
    width: fit-content !important;
}

.tabIcon {
    height: 56px;
}

.tabTitle {
    margin-left: -30px !important;
    font-weight: 400;
    color: #717171;
}

.activeTabTitle {
    color: var(--black-color);
    font-size: 700;
}

/* Jobs */

.jobsContainer {
    margin: 40px 0;
}