.record {
    display: flex;
    align-items: center;
    row-gap: 20px;
    column-gap: 10px;
    background-color: #1e1e1e;
    border-radius: 20px !important;
}

.deleteIconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #CD0000;
    color: white;
    width: 34px;
    height: 34px;
    cursor: pointer;
    border-radius: 30px;
    margin-right: 10px;
}

.deleteIconContainer:hover {
    background-color: #A40000;
}

.deleteIconContainer:active {
    background-color: #720000;
}