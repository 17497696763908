.controlBtnsContainer {
    display: flex;
    height: 100%;
}

.recordField {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    border: 0.8px solid #CCCCCC;
    border-radius: 5px;
    height: 56px;
    padding-left: 16px; 
}

.recordField .recordName {
    font-size: var(--small-size);
}

.controlBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 42px !important;
    text-align: center;
    cursor: pointer;
}

.cancelVoiceContainer {
    border: 0.8px solid #CD0000;
    border-radius: 5px 0 0 5px;
}

.cancelVoiceContainer p {
    color: #CD0000;
}

.resumeVoiceContainer {
    border: 0.8px solid #0096FF;
}

.resumeVoiceContainer p {
    color: #0096FF;
}


.pauseVoiceContainer {
    border: 0.8px solid #0096FF;
}

.pauseVoiceContainer p {
    color: #0096FF;
}

.disabledSaveVoiceContainer {
    border: 0.8px solid #CCCCCC;
    background-color: var(--white-color);
    border-radius: 0 5px 5px 0;
}

.disabledSaveVoiceContainer p {
    color: #CCCCCC;
}

.saveVoiceContainer {
    border: 0.8px solid var(--primary-color);
    border-radius: 0 5px 5px 0;
}

.saveVoiceContainer p {
    color: var(--primary-color);
}

.recordVoiceContainer {
    border: 0.8px solid var(--primary-color);
    border-radius: 0 5px 5px 0;
}

.recordVoiceContainer p {
    color: var(--primary-color);
}



/* When the browser is at least 1536px and beyond // xs */
@media (max-width: 0px) {

}
/* When the browser is at least 800px and beyond // sm */
@media (max-width: 800px) {
    .recordField {
        height: fit-content;
        justify-content: center;
        padding-left: 0;
    }

    .recordingTime {
        margin: 10px 0;
        font-size: var(--body1-size);
    }

    .controlBtnsContainer {
        height: 56px;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    
    .controlBtn {
        flex-grow: 1;
        padding: 0 10px !important;
    }

    .recordVoiceContainer {
        border: 0.8px solid var(--primary-color);
        border-radius: 5px;
    }
}

/* When the browser is at least 900px and beyond // md */
@media (max-width: 900px) {
    .controlBtn {
        padding: 0 27px;
    }
    
}

/* When the browser is at least 1200px and beyond // lg */
@media (max-width: 1200px) {

}

/* When the browser is at least 1536px and beyond // xl */
@media (max-width: 1536px) {

}