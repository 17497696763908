.container {
    margin: 0 30px;
}

.applicationDetails {
    margin-top: 10px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 32px 24px;
    margin-bottom: 32px !important;
}

.applicationDetailsData {
    display: grid;
    margin-top: 24px !important;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 16px;
}

.applicationDetailData {
    color: #717171;
}

.applicationDetailValue {
    color: #000000;
}

.screeningQuestions {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 32px 24px !important;
    margin-bottom: 10px !important;
}

.screeningQuestionsContainer {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    row-gap: 8px !important;
    margin-top: 24px !important;
}

.screeningQuestion {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.question {
    display: flex;
    width: 100%;
    color: var(--text-color);
}

.question p {
    font-weight: 800;
}

.question img {
    margin-right: 8px !important;
}

.questionAnswer {
    display: flex;
    margin-top: 5px;
    width: 100%;
    color: var(--black-color);
}


/* When the browser is at least 1536px and beyond // xs */
@media (max-width: 0px) {

}
/* When the browser is at least 600px and beyond // sm */
@media (max-width: 600px) {
  .container {
    margin: 0 10px !important;
  }

  .container span {
    display: block;
    word-break: break-all;
  }

  .controlBtnContainer {
    justify-content: center !important;
  }

  .jobContainer {
    padding: 18px 25px !important;
  }
}

/* When the browser is at least 600px and beyond // md */
@media (max-width: 1000px) {
    .applicationDetailsData {
        grid-template-columns: none;
    }
}

/* When the browser is at least 1200px and beyond // lg */
@media (max-width: 1200px) {
}

/* When the browser is at least 1536px and beyond // xl */
@media (max-width: 1536px) {

}