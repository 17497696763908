.firstHomeContainer {
    position: relative;
    min-height: calc(100vh - 64px);
    padding-top: 64px;
    background-image: url("../../assets/images/home-bg.png");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
}

.backgroundShadowLayer {
    position: absolute;
    top: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.32);
    width: 100%;
}

.sloganContainer {
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    width: 100%;
    height: 72px;
}

.firstHomeTextHolder {
    justify-content: "center"; 
}

.firstHomeTextContainer {
    z-index: 1;
    text-align: center;
    margin-left: 0;
}

.firstHomeTitle {
    margin-bottom: 30px !important;
    color: var(--white-color);
}

.eliteSpan {
    color: var(--primary-color);
}

.learnMoreBtn {
    color: var(--white-color);
    font-size: 20px;
    font-weight: 500;
    margin-top: 24px;
    border-radius: 5px;
    background-color: var(--primary-color);
    border: none;
    padding: 14px 24px;
    cursor: pointer;
}

/* When the browser is at least 1536px and above // xs */
@media (min-width: 0px) {

  }
/* When the browser is at least 600px and above // sm */
@media (min-width: 600px) {
  }

/* When the browser is at least 900px and above // md */
@media (min-width: 900px) {
    .firstHomeTextHolder {
        justify-content: flex-start;
    }
    .firstHomeTextContainer {
        text-align: left;
        margin-left: 114px;
    }
  }

/* When the browser is at least 1200px and above // lg */
@media (min-width: 1200px) {

  }

/* When the browser is at least 1536px and above // xl */
@media (min-width: 1536px) {

  }