.bgImgContainer {
    position: relative;
    height: 736px;
}

.bgImg { 
    height: 100%;
    width: 100%;
    object-fit: cover;
    background-origin: padding-box;
}

.headerContainer {
    margin-top: 61px !important;
}

.headerIcon {
    height: 70px;
}

.headerTitle {
    margin-left: -40px !important;
    font-size: 700;
}

.cardsContainer {
    margin-top: 72px !important;
}

.cardContainer {
    width: 376px !important;
    height: 342px !important;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin: 15px calc(48px / 2);
}

.icon {
    height: 80 !important;
    width: 80 !important;
}

.cardText {
    color: var(--text-color);
    margin-top: 32px !important;
}

.email {
    text-decoration-line: underline;
}

.contactFormContainer {
    margin: 0 60px;
}

.calendyContainer {
    margin-top: 96px !important;
    height: 622px !important;
    width: 100%;
    overflow-y: none;
}

/* When the browser is at least 600px and below // sm */
@media (max-width: 600px) {
    .contactFormContainer {
        margin: 0 20px;
    }
}
