.mainContainer {
    max-width: 1240px;
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
}

.mainContainer a {
    color: var(--primary-color);
}

.mainContainer a:hover {
    color: #165399;
}

.title {
    color: var(--primary-color);
    font-size: 34px;
    text-align: center;
}

/* When the browser is at least 1200px and below // lg */
@media (max-width: 1300px) {
    .mainContainer {
        padding: 0 20px;
    }
}