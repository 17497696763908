/* Why Choose Us */

.headerContainer {
    margin-top: 80px;
}

.headerIcon {
    height: 70px;
}

.headerTitle {
    margin-left: -40px !important;
    font-size: 700;
}

.cardsContainer {
    margin-top: 52px;
    margin-bottom: 96px;
}


/* When the browser is at least 1536px and above // xs */
@media (min-width: 0px) {

}
/* When the browser is at least 600px and above // sm */
@media (min-width: 600px) {
}

/* When the browser is at least 900px and above // md */
@media (min-width: 900px) {

}

/* When the browser is at least 1200px and above // lg */
@media (min-width: 1200px) {

}

/* When the browser is at least 1536px and above // xl */
@media (min-width: 1536px) {

}