.titleContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 100px !important;
}

.titleShortInfo {
    font-size: var(--small-size);
    color: #717171;
    margin-top: 16px !important;
}


/* header filter */

.headerContainerFlex {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 50px !important;
}

.headerContainer {
    width: 998px;
    display: flex;
    column-gap: 24px;
}

.searchInputContainer {
    position: relative;
    display: flex;
    align-items: center;
    height: 45px;
    border: 0.8px solid #CCCCCC;
    border-radius: 5px;
    font-size: var(--small-size);
    padding-left: 16px;
    flex-grow: 1;
}

.searchInputContainer:focus-within{
}

.searchIcon {
    position: absolute;
    height: 16px;
    width: 16px;
}

.searchInput {
    margin-left: 25px;
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    background: none;
    font-size: var(--small-size);
}

.addBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px;
    background-color: var(--primary-color);
    color: var(--white-color);
    border: none;
    font-size: var(--small-size);
    border-radius: 5px;
    cursor: pointer;
}

.filterIcon {
    height: 16px;
    width: 16px;
    margin-left: 8px;
}

.filterContainerFlex {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

.filterContainer {
    width: 820px;
    display: flex;
    justify-content: space-between;
}

.filterLeftSection {
    display: flex;
    column-gap: 24px;
}

/* Tab section */

.tabsContainer {
    display: flex;
    width: 100%;
    margin-top: 96px !important;
    column-gap: 24px;
}

.tabContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content !important;
}

.tabIcon {
    height: 56px;
}

.tabTitle {
    margin-left: -30px !important;
    font-weight: 400;
    color: #717171;
}

.activeTabTitle {
    color: var(--black-color);
    font-size: 700;
}

.filterRightSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 16px;
}

.sortBy {
    color: #717171;
    font-weight: 400;
}

select {
    appearance: none;
    background-image: url("../../../../assets/images/down-arrow-icon.svg");
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: calc(100% - 16px) center;
}

.selectFilterField {
    box-sizing: border-box;
    background-color: var(--secondary-color);
    color: var(--text-color);
    border: none;
    outline: none;
    border-radius: 5px;
    font-size: var(--small-size);
    padding-right: 48px;
    padding-left: 24px; 
    padding-top: 12px;
    padding-bottom: 12px;
}

/* processes container */

.processesContainer {
    display: flex;
    column-gap: 24px;
    padding: 0 112px;
    padding-bottom: 40px;
    margin: 40px 0;
    overflow-x: auto;
    overflow-y: hidden;
}

.processContainer {
    min-width: 440px;
}

.processTitleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary-color);
    height: 76px;
    border-radius: 5px 5px 0px 0px;
}

.processTitle {
    font-weight: 400;
}

/* Application */

.applicationsContainer {
    display: flex;
    flex-direction: column;
    margin-top: 24px !important;
    row-gap: 24px !important;
    height: 100%;
}