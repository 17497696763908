.container {

}

.avatar {
    cursor: pointer;
}

.menu {
    margin-top: 45px;
}

.menu li {
    height: 40px;
}

.menuText {
    text-align: center;
}