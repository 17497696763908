.container {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.notLoggedInContainer {
    display: flex;
}

.notLoggedInContainer p {
    font-size: var(--body1-size);
    margin: 0 10px;
    cursor: pointer;
}

.emptyLoggedInSection {
    width: 40px;
}