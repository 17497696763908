.container {
    margin-top: 93px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 37px;
}

.halfWidthField {
    width: 50%;
}

.fullWidthField {
    width: 100%;
}

.leftField {
    box-sizing: border-box !important;
    padding-right: 56px !important;
}

.fieldLabel {
    color: #717171;
    margin-bottom: 4px !important;
}

.textField {
    width: 100%;
    box-sizing: border-box;
    border: 0.8px solid #CCCCCC;
    border-radius: 5px;
    height: 56px;
    font-size: var(--small-size);
    padding-left: 16px;
}

select {
    appearance: none;
    background-image: url("../../../assets/images/down-arrow-icon.svg");
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: calc(100% - 16px) center;
}

.selectField {
    width: 100%;
    box-sizing: border-box;
    border: 0.8px solid #CCCCCC;
    border-radius: 5px;
    height: 56px;
    font-size: var(--small-size);
    padding-right: 50px;
    padding-left: 16px; 
}

.tagsContainer {
    display: flex;
    gap: 16px;
}

.tagContainer {
    display: flex;
    width: fit-content;
    padding: 12px 24px;
    border: 0.8px solid #CCCCCC;
    border-radius: 5px;
    cursor: pointer;
}

.selectedTagContainer {
    background-color: grey !important;
    color: white !important;
}

.addIcon {
    margin-left: 12px !important;
}

.yearsOfExperienceContainer {
    height: 56px;
    display: flex;
    border: 0.8px solid #CCCCCC;
    border-radius: 5px;
    width: fit-content;
}

.yearsOfExperienceContainer select {
    border: none;
    padding-left: 16px;
    color: var(--text-color);
    font-size: var(--body1-size);
    width: 140px;
    outline: none;
}

.yearsOfExperienceContainer select:nth-child(1) {
    border-right: 0.8px solid #CCCCCC;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.yearsOfExperienceContainer select:nth-child(2) {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}


.salaryContainer {
    height: 56px;
    display: flex;
    border: 0.8px solid #CCCCCC;
    border-radius: 5px;
    width: fit-content;
}

.salaryContainer input {
    border: none;
    padding-left: 16px;
    color: var(--text-color);
    font-size: var(--body1-size);
    width: 140px;
    outline: none;
}

.salaryContainer input:nth-child(1) {
    border-right: 0.8px solid #CCCCCC;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.salaryContainer input:nth-child(2) {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.vacanciesContainer {
    display: flex;
    height: 56px;
    border: 0.8px solid #CCCCCC;
    border-radius: 5px;
    width: fit-content;
}

.vacanciesControl {
    height: 100%;
    width: 56px;
    border: none;
    cursor: pointer;
}

.vacanciesInput {
    height: 100%;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    text-align: center;
    color: var(--text-color);
    font-size: var(--h3-size);
    width: 120px;
}

.subtractVacanciesBtn {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.disableBtn {
    opacity: .5;
}

.addVacanciesBtn {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}