.container {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 37px;
}

.fileField {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    border: 0.8px solid #CCCCCC;
    border-radius: 5px;
    height: 56px;
    padding-left: 16px; 
    cursor: pointer;
}

.fileField input[type="file"] {
    display: none;
}

.fileField .fileName {
    font-size: var(--small-size);
}

.browseFileContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 42px !important;
    border: 0.8px solid var(--primary-color);
    height: 100%;
    border-radius: 0 5px 5px 0;
    text-align: center;
}

.browseFileText {
    color: var(--primary-color);
}

.halfWidthField {
    width: 50%;
}

.fullWidthField {
    width: 100%;
}

.requiredAsterisk {
    color: red;
}

.leftField {
    box-sizing: border-box !important;
    padding-right: 56px !important;
}

.fieldLabel {
    color: #717171;
    margin-bottom: 4px !important;
}

.textField {
    width: 100%;
    box-sizing: border-box;
    border: 0.8px solid #CCCCCC;
    border-radius: 5px;
    height: 56px;
    font-size: var(--small-size);
    padding-left: 16px; 
}

select {
    appearance: none;
    background-image: url("../../assets/images/down-arrow-icon.svg");
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: calc(100% - 16px) center;
}

.selectField {
    width: 100%;
    box-sizing: border-box;
    border: 0.8px solid #CCCCCC;
    border-radius: 5px;
    height: 56px;
    font-size: var(--small-size);
    padding-right: 50px;
    padding-left: 16px; 
}

.yearsExperienceField {
    margin-right: 10px;
}

.salaryField {
    display: flex;
    align-items: center;
}

.salaryField p {
    font-size: var(--body1-size);
    width: 100%;
    margin-left: 30px;
}

.cvHelperText {
    font-size: var(--smallest-size);
    font-weight: 400;
    display: block;
    letter-spacing: -0.3px;
    line-height: 20px;
    color: rgb(128, 142, 165);
}


/* When the browser is at least 1536px and beyond // xs */
@media (max-width: 0px) {

}
/* When the browser is at least 600px and beyond // sm */
@media (max-width: 600px) {
    .salaryField {
        flex-wrap: wrap;
        row-gap: 20px;
        justify-content: center;
    }

    .salaryField p {
        margin-left: 0px;
        text-align: center;
    }

    .leftField {
        padding-right: 15px !important;
    }

    .browseFileContainer {
        padding: 0 10px !important;
    }
}

/* When the browser is at least 900px and beyond // md */
@media (max-width: 900px) {

}

/* When the browser is at least 1200px and beyond // lg */
@media (max-width: 1200px) {

}

/* When the browser is at least 1536px and beyond // xl */
@media (max-width: 1536px) {

}