.container {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 32px 24px;
}

.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}

.headerTitle {
    font-weight: 700;
}

.closeBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary-color);
    border-radius: 5px;
    outline: none;
    border: none;
    height: 32px;
    width: 32px;
    cursor: pointer;
}

.tagsContainer {
    display: flex;
    gap: 16px;
    margin-top: 8px;
}

.tagContainer {
    display: flex;
    width: fit-content;
    padding: 12px 24px;
    border: 0.8px solid #CCCCCC;
    border-radius: 5px;
    cursor: pointer;
}

.selectedTagContainer {
    background-color: grey !important;
    color: white !important;
}

.addIcon {
    margin-left: 12px !important;
}

.questionTitleContainer {
    margin-top: 22px;
}

.textField {
    width: 100%;
    box-sizing: border-box;
    border: 0.8px solid #CCCCCC;
    border-radius: 5px;
    height: 56px;
    font-size: var(--small-size);
    margin-top: 8px;
    padding-left: 10px;
}

.saveBtnContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
}

.saveBtn {
    display: flex;
    align-items: center;
    background: var(--primary-color);
    color: var(--white-color);
    border: none;
    outline: none;
    padding: 16px 24px;
    border-radius: 5px;
    font-size: var(--body1-size);
    font-weight: 500;
    cursor: pointer;
}

.saveBtn img {
    margin-left: 8px;
}