.container {
    margin-top: 96px !important;
}

.headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 64px !important;
}

.questionFiltersContainer {
    display: flex;
    column-gap: 24px;
}

.questionFilter {
    color: #717171;

}

.newQuestionBtn {
    display: flex;
    align-items: center;
    background-color: var(--primary-color);
    color: var(--white-color);
    padding: 12px 24px;
    border: none;
    border-radius: 5px;
    font-size: var(--small-size);
    cursor: pointer;
}

.addIcon {
    margin-left: 8px;
}

.sectionTitle {
    font-size: var(--h3-size);
    font-weight: 700;
    margin-top: 32px !important;
}

.questionsContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 24px !important;
    row-gap: 16px;
}

.questionContainer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    border: 0.8px solid #CCCCCC;
    border-radius: 5px;
    padding: 17px 24px;
}

.inactiveQuestion {
    opacity: 0.5;
}

.leftQuestionSection {
    display: flex;
}

.whoAnsweredCount {
    font-size: var(--smallest-size);
    color: var(--primary-color);
}

.leftQuestionSection p {
    margin-left: 8px !important;
}

.displayIcon {
    height: 17px;
    width: 24px;
}

.controlBtnsContainer {
    display: flex;
    column-gap: 8px;
}

.controlBtnsContainer img {
    cursor: pointer;
}