.jobContainer {
    padding: 32px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.editMenuBtn {
    text-decoration: none;
    color: #000000DE
}

.inactiveJob {
    opacity: 0.5;
}

.jobTitle {

}

.jobIconContainer {
    width: fit-content !important;
}

.jobIconContainer img {
    cursor: pointer;
}

.jobDetailsContainer {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 12px;
    margin-top: 16px;
}

.locationVacanies {
    color: #717171;
}

.jobViewsApplicants {
    color: var(--text-color);
}

.newStrongApplicants {
    color: var(--primary-color);
    text-align: right;
}

.postDate {
    color: #1F8EBA;
}

.jobTypesContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    row-gap: 5px;
}

.jobTypeContainer {
    display: flex;
    width: 50%;
    align-items: center;
    flex-wrap: nowrap;
    column-gap: 8px;
}

.jobTypeIconText {
    color: var(--text-color);
    font-size: var(--small-size) !important;
}


.notViewed {
    color: var(--text-color);
    text-align: right;
}

.viewCandidatesBtnContainer {
    margin-top: 20px;
    width: 100%;
}

.navigateBtn {
    text-decoration: none;
}

.viewCandidatesBtn {
    display: flex;
    justify-content: center;
    height: fit-content;
    width: 100%;
    font-size: var(--body1-size);
    align-items: center;
    background: var(--primary-color);
    border: 0.8px solid var(--primary-color);
    color: var(--white-color);
    border-radius: 5px;
    padding: 16px 24px;
    cursor: pointer;
}

.viewCandidatesBtn img {
    margin-left: 8px !important;
}