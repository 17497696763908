.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 80px !important;
    width: 100%;
    margin-bottom: 100px;
}

.notFoundImg {
    height: 300px;
    width: 300px;
}

.code {
    margin-top: 10px !important;
    text-align: center;
}

.title { 
    margin-top: 10px !important;
    text-align: center;
}

.description {
    margin-top: 10px !important;
    color: var(--text-color);
    text-align: center;
}


/* When the browser is at least 1536px and above // xs */
@media (min-width: 0px) {
}
/* When the browser is at least 600px and above // sm */
@media (min-width: 600px) {
    .container {
        margin-top: 0px;
    }
}

/* When the browser is at least 900px and above // md */
@media (min-width: 900px) {
}

/* When the browser is at least 1200px and above // lg */
@media (min-width: 1200px) {

}

/* When the browser is at least 1536px and above // xl */
@media (min-width: 1536px) {

}